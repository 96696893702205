import * as React from "react"
import {Motion, spring} from "react-motion"

export interface FadeProps {
    delay?: number
    from?: string
    once?: boolean
    className?: string
    children: any
}

export interface FadeState {
    delayed?: boolean
    timer?: ReturnType<typeof setTimeout>
}

export default class Fade extends React.Component<FadeProps, FadeState> {

    constructor(props: Readonly<FadeProps> | FadeProps) {
        super(props);

        this.state = {delayed: false}

        let delay = this.props.delay

        if (delay > 0) {
            this.state = {
                delayed: true,
                timer: setTimeout(() => this.setState({delayed: false}), delay)
            }
        }
    }

    componentWillUnmount(): void {
        clearTimeout(this.state.timer)
    }

    render() {
        if (this.state.delayed) {
            return <div style={{visibility: "hidden"}}>{this.props.children}</div>
        }

        return (
            <Motion
                defaultStyle={{x: -100, opacity: 0}}
                style={{
                    x: spring(0),
                    opacity: spring(1, {stiffness: 50, damping: 40}),
                }}
            >
                {interpolatingStyle => {
                    let transform = "";
                    if (this.props.from === "top")
                        transform = `translateY(${interpolatingStyle.x}px)`;
                    if (this.props.from === "bottom")
                        transform = `translateY(${-interpolatingStyle.x}px)`;
                    if (this.props.from === "left")
                        transform = `translateX(${interpolatingStyle.x}px)`;
                    if (this.props.from === "right")
                        transform = `translateX(${-interpolatingStyle.x}px)`;
                    return (
                        <div
                            className={this.props.className}
                            style={{
                                transform: transform,
                                opacity: interpolatingStyle.opacity,
                            }}
                        >
                            {this.props.children}
                        </div>
                    )
                }}
            </Motion>
        )
    }
}
