import * as React from "react"
import {graphql, Link, StaticQuery} from "gatsby"
import loaded from "../context/loaded-context"

import 'bootstrap/dist/css/bootstrap.min.css';
import "./layout.scss"
import {Helmet} from "react-helmet"
import Fade from "./fade";
// @ts-ignore
import JakeWise from "../assets/jakewise.svg"
import {Location} from "@reach/router"
import {FaArrowLeft, FaLightbulb, FaRegLightbulb, FaUserCircle} from 'react-icons/fa';

export default ({children}) => (
    <StaticQuery
        query={graphql`query {
          site {
            siteMetadata {
              title
              description
            }
          }
        }`}
        render={(data) => (<Layout metadata={data.site.siteMetadata}>{children}</Layout>)}/>
)

export interface LayoutProps {
    metadata: {
        title: string
        description: string
    }
    children: any
}

export interface LayoutState {
    dark: boolean
}

export class Layout extends React.Component<LayoutProps, LayoutState> {

    constructor(props: Readonly<LayoutProps> | LayoutProps) {
        super(props);

        this.state = {
            dark: false
        }

        setTimeout(() => loaded.header = true, 2000)
    }

    render() {
        return (
            <div className="page full-height">
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>{this.props.metadata.title}</title>
                    <meta name="description" content={this.props.metadata.description}/>
                </Helmet>
                <header>
                    <div className="navigation">
                        <Fade delay={2000}>
                            <SocialLink/>
                        </Fade>
                    </div>
                    <div className="title">
                        <Link to="/"><JakeWise/></Link>
                    </div>
                </header>
                {this.props.children}
                <footer>
                    <Fade delay={1000}>
                        <span style={{cursor: "pointer"}} onClick={this.toggleDarkMode}>
                            <FaLightbulb size={28}/>
                        </span>
                    </Fade>
                </footer>
            </div>
        )
    }

    toggleDarkMode = () => {
        document.body.classList.toggle("dark")

        this.setState({
            dark: document.body.classList.contains("dark")
        })
    }
}

const SocialLink = () => (
    <Location>
        {({location}) =>
            !location.pathname.match('^/social/?$') ? (
                <Link to={"/social"}
                      title="follow or message me on social media"
                      aria-label="social media links"
                      className="contact">
                    <FaUserCircle size={"1.4em"}/>
                </Link>
            ) : (
                <Link to={"/"}
                      title="return to front page"
                      className="home">
                    <FaArrowLeft size={"1.4em"}/>
                </Link>
            )
        }
    </Location>
)
